import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import PrinterGroup from './PrinterGroup'
import BonTypeNames from './BonTypeNames'
import PrintStatus from './PrintStatus'

const entity = {
  printStatus: {
    displayName: 'printStatus',
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: PrintStatus.getList(),
        itemText: 'name',
      },
    },
  },
  printBonClass: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: BonTypeNames.getList(),
      },
    },
  },
  printerGroupId: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: PrinterGroup.getList,
      },
      width: '150px',
    },
  },

  addTimeStamp: {
    type: IKDataEntity.Types.Date,
    displayName: 'addTimeStamp',
    formConfig: {
      width: '150px',
    },
  },

  printerMsg: {
    displayName: 'printerMsg',
  },
  content: {
    displayName: 'checkContent',
    header: false,
  },
}
const config = {
  load: async function (filter) {
    const list = (await hillo.get('PrintRecord.php', {
        op: 'getAllRecords',
      })
    ).content

    filter = filter[0]

    if (filter) {
      return list.filter(i => {
        return i.printStatus !== filter.printStatus
      })
    }
    return list
  },
  reprint: function (item) {
    return hillo.get('Printer.php?op=reprintWithDefault', { id: item.id })
  },
}

export default IKDataEntity.ModelFactory(entity, config)
